import { useRef, useEffect } from 'react';
import AnimatedNumber from '../AnimatedNumber';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './style.scss';

gsap.registerPlugin(ScrollTrigger);

let researchBlocks = [
  {
    title: 'Збір та аналіз даних',
    description:
      'Проаналізували інформацію про місто з різних джерел для формування комплексного бачення',
  },
  {
    title: 'Аналіз конкурентів',
    description:
      'Проаналізували міста, які можуть становити конкуренцію Чернівцям у різних сферах',
  },
  {
    title: 'Аналіз медіа перцепції міста',
    description:
      'Дослідили висловлювання людей у соціальних мережах для розуміння сприйняття Чернівців',
  },
  {
    title: 'Кількісне дослідження',
    description:
      'Провели опитування жителів Чернівців щодо сильних та слабких сторін міста, асоціацій із містом',
  },
  {
    title: 'Якісне дослідження',
    description:
      'Організували глибинні інтерв`ю з 10 учасниками різних соціально-демографічних груп',
  },
  {
    title: 'Залучення громади',
    description:
      'Провели голосування та відкриті обговорення з активними містянами та членами робочої групи проєкту.',
  },
];

const Hero = () => {
  const blocksRef = useRef([]);

  useEffect(() => {
    blocksRef.current.forEach((block, index) => {
      const imgActive = block.querySelector('.research-block__img-active');

      gsap.fromTo(
        imgActive,
        { opacity: 0 },
        {
          opacity: 1,
          scrollTrigger: {
            trigger: block,
            start: 'top center',
            end: 'bottom center',
            toggleActions: 'play none none reverse',
          },
        }
      );
    });
  }, []);

  return (
    <>
      <section className='hero'>
        <div className='container'>
          <div className='hero__wrapper'>
            <div className='hero-title'>
              <h1>Розробка бренду міста Чернівці</h1>
              <img
                src='./img/hero-svg.svg'
                alt=''
              />
              <p>
                Головною метою цього проєкту було створення впізнаваного,
                унікального та сучасного бренду для міста Чернівці. Новий бренд
                міста відобразив історію, архітектуру та культурну спадщину
                Чернівців.
              </p>
            </div>
            <div className='hero-partners'>
              <img
                src='./img/partners/partners-white.png'
                alt=''
              />
              <p>
                «EU4Business: відновлення, конкурентоспроможність та
                інтернаціоналізація МСП», що спільно фінансується Європейським
                Союзом та урядом Німеччини і реалізується німецькою федеральною
                компанією Deutsche Gesellschaft für Internationale
                Zusammenarbeit (GIZ) GmbH здійснилась розробка бренду міста
                Кременчук.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className='research'
        id='research'>
        <div className='container'>
          <div className='research__wrapper'>
            <h2 className='research-title'>Дослідження</h2>
            <p className='research-text'>
              Для глибокого розуміння поточного стану та потенціалу розвитку
              бренду Чернівців ми здійснили:
            </p>
            <div className='research-blocks'>
              {researchBlocks.map((item, index) => (
                <div
                  className='research-block'
                  key={index}
                  ref={(el) => (blocksRef.current[index] = el)}>
                  <div className='research-block__img'>
                    <img
                      className='research-block__img-dis'
                      src='./img/research-icon.svg'
                      alt=''
                    />
                    <img
                      className='research-block__img-active'
                      src='./img/research-icon-active.svg'
                      alt=''
                    />
                  </div>
                  <div className='research-block__text'>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='numbers'>
            <div className='numbers__wrapper'>
              <div className='numbers-block'>
                <h3>Відкрили опитування</h3>
                <AnimatedNumber value='1174' />
              </div>
              <div className='numbers-block'>
                <h3>Відсоток заповнень</h3>
                <AnimatedNumber value='58.6%' />
              </div>
              <div className='numbers-block'>
                <h3>Заповнили опитування</h3>
                <AnimatedNumber value='688' />
              </div>
              <div className='numbers-block'>
                <h3>Час для заповнення</h3>
                <AnimatedNumber value='32:46' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
